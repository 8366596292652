.divider {
  margin-left: 2%;
}
.dropdown-toggle::after {
  display: none !important;
  border: 0;
}

.vertical_dotted_line {
  margin-top: 30px;
  border-left: 1px dashed #c4c4c4;
  height: 100px;
}

.dropdown {
  background-color: white;
  border-color: white;
  width: max-content;
}
.dropdown-menu {
  width: max-content;
}
.griditem {
  display: inline-grid;
  justify-items: center;
  align-items: center;
}
.counter {
  background-color: #5f41b3 !important;
  border-radius: 16px;
  font-size: 1vw;
}
#dropdown-basic{
  background-color: transparent;
  border-radius: 15px;
  padding: 0.5rem;
  border: 1px solid #C4C4C4;
  min-width: 300px;

}