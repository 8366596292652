@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}
.card {
  height: 380px;
  width: 350px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  padding: 20px;
}
.text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.text p {
  font-size: 13px;
  color: #bac4d4;
  font-weight: 700;
}
.input-text {
  position: relative;
  margin-top: 30px;
}
input[type="text"] {
  height: 45px;
  width: 100%;
  border: none;
  border-radius: 9px;
  /* border: 1px solid #e0e3e9; */
  padding: 0 10px;
  padding-left: 30px;
  outline: 0;
  font-size: 13px;
}
input[type="password"] {
  height: 45px;
  width: 100%;
  border: none;
  border-radius: 9px;
  /* border: 1px solid #e0e3e9; */
  padding: 0 10px;
  padding-left: 30px;
  padding-right: 27px;
  outline: 0;
}
.fa-envelope {
  position: absolute;
  left: 10px;
  cursor: pointer;
  top: 15px;
}
.fa-lock {
  position: absolute;
  left: 12px;
  top: 35px;
  cursor: pointer;
  font-size: 15px;
}
.input-text .fa-eye-slash {
  position: absolute;
  right: 10px;
  top: 50px;
  cursor: pointer;
}
.input-text .fa-eye-slashSignUp {
  position: absolute;
  right: 10px;
  top: 35px;
  cursor: pointer;
}
.fa-eye {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 15px;
  cursor: pointer;
}
.buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TextField-without-border-radius fieldset {
  border-radius: 0;
}
.inputRounded .MuiOutlinedInput-root {
  border-radius: 50px;
}
.buttons button {
  height: 45px;
  width: 100%;
  border: none;
  background-color: #267fff;
  border-radius: 9px;
  font-size: 12px;
  outline: 0;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s;
}
.buttons button:hover {
  background-color: #083c87;
}
.forgot {
  margin-top: 30px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forgot a {
  text-decoration: none;
}

h1 {
  font-family: Lato;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
  color: red;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  opacity: 1;
  animation-name: fadeDownOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
  color: blue;
}

@keyframes fadeDownOpacity {
  100% {
    opacity: 0;
  }
  0% {
    opacity: 1;
  }
}
