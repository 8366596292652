/* Search Form */
* {
  box-sizing: border-box;
  padding:0px;

}

// body {

//   font-family: sans-serif;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }
#flight-form {
  margin: 0;
}

#search-form {
  position: relative;
  background: white;
}



.flight {
  border: 0px solid white;
  
}
.flight input, select {
    color: black;
}

.info-box {
  padding: 10px 0;
  border-right: 0px solid transparent;


}
.info-box:last-child {
  border-right: none;
  
}
.info-box label {
  display: block;
 color: #5D5C66;
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
  text-align: left;
}

.flights {
  input{
outline: none;
  background: white;
  padding: 5px;
height: 42px;
border-radius: 15px;
border: 1px solid  #C4C4C4;
  }
svg{
      left: 26px;
}
}

.info-box input[type=text], input[type=submit], select {
  outline: none;
  background: white;
  padding: 5px;
height: 42px;
border-radius: 15px;
border: 1px solid  #C4C4C4;

}

#input-wrapper {
  margin-top: 5px;
}

#pax-type {
  max-width:105px;
 outline: none;
  background: white;
border:none;
color: #5F41B2;
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-overflow: ellipsis;
}


#class-type {
  max-width:105px;
 outline: none;
  background: white;
  margin-top: 5px;
  margin-right: 10px;
  padding: 5px;
height: 42px;
border:none;
color: #5F41B2;
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-overflow: ellipsis;
}

#flight-class-pax {
 background: white;
  padding: 5px;
border:none;
}

#flight-type {
  display: flex;
  justify-content: space-between;
margin-right: 20px;
}
#flight-type .info-box {
  display:flex;
  align-items: center;
  gap: 8px;

}

#flight-way {
display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

  #flightroute {
            accent-color: #5F41B2;
            margin-left: 20px;
        }

#flight-details {
display: flex;
      gap: 22px;
}

#flight-depart {
  display: flex;
  justify-content: space-between;
}

#dep-from {
width: 300px;

}

#dep-to{
width: 300px;

}

#leave-date {
width: 219px;

}

#return-date {
width: 219px;

}

#flight-depart .info-box {
  position: relative;
}
#depart-res, #arrive-res {
  position: absolute;
  background: white;
}
#depart-res p, #arrive-res p {
  padding: 7px 2px;
}
#depart-res p:hover, #arrive-res p:hover {
  background: white;
  cursor: pointer;
}

#flight-dates {
  display: flex;
}

#flight-info {
  display: flex;
  color: #5D5C66;
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

#flight-search {
    display: flex;
    justify-content: center;
    align-items: center;
}

#search-flight {
  display: flex;
  justify-content: center;
align-items: center;
width: 300px;
height: 42px;
padding: 11px 89px 12px 90px;
color: #fff;
flex-shrink: 0;
border-radius: 10px;
background: #5F41B2;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}


/* Calender */
#calender {
  border: 3px solid white;
  padding: 5px;
  opacity: 0;
  background: white;
  position: absolute;
  transition: opacity .3s;
  pointer-events: none;
}
#calender .nav {
  padding: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
#calender .nav button {
  background: white;
  border: none;
  outline: none;
  color: white;
  padding: 5px 8px;
  font-weight: 600;
  border-radius: 3px;
}
table {
  border: 1px solid white;
  background: white;
}
table td {
  border: 1px solid #DBDBDB;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}
.normal {
  color: rgba(0,0,0,.8)
}
.today {
  background: white;
  color: black;
}
.closed {
  color: black;
  cursor: default;
}
.picked {
  background: white;
}
#month {
  display: inline-block;
}

#confirm {
  width: 500px;
  padding: 20px;
  border: 5px solid white;
  margin: 20px auto;
  opacity: 0;
}
#confirm h3 {
  font-size: 1.2em;
  margin-bottom: 20px;
}
#confirm p {
  padding: 10px;
}
#confirm strong {
  font-weight: 600;
}
