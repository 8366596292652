.react-google-flight-datepicker .date-picker-date-group {
  height: auto;
}

.react-datepicker {
  .date {
    padding: 0.5rem 1rem;
    border-radius: 15px;
    border: 1px solid #C4C4C4;
  }
  .selected-date {
    overflow: visible;

  }
}
.react-single__datepicker {
   border-radius: 15px;
    border: 1px solid #C4C4C4;
}
