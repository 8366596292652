#grouped-demo {
  height:30px;
  padding-left: 45px;
padding-right: 35px;
}

.MuiAutocomplete-hasPopupIcon div.MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon div.MuiAutocomplete-inputRoot {
    padding-right: 0;
}

.MuiAutocomplete-endAdornment {
    top: calc(50% - 14px) !important;
    right: 15px !important;
    position: absolute !important;
}