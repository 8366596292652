.buttons {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttons button {
  height: 45px;
  width: 100%;
  border: none;
  background-color: #267fff;
  border-radius: 9px;
  font-size: 12px;
  outline: 0;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s;
}
