.input-text {
    position: relative;
    height: 42px;
    background: #FFFFFF;
    border-radius: 15px;

   
  }

  .input-text .logo {
    position: absolute;
    left: 10px;
    top: 30px;
    cursor: pointer;
  }

  input.form-control {
    box-sizing: border-box;
    text-indent: 35px;
    border: 1px solid #C4C4C4;
  
  }

  .date-picker {
    position: relative;
    border-radius: 4px;
    background: white;
    padding: 6px;

  
  }





  
