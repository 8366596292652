@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800&display=swap');

.insta-profile {
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-wrap: wrap;
     align-items: center;
     justify-content:space-between;
     text-align: center;
     margin: 0 auto;
     width : 75%;
}
.insta-profile-image {
    top : -50px;
     border : 2px solid black;
     box-shadow: 2px 2px 3px #999;

}
.insta-info { 
    align-items: end;
    flex-basis: 60%;
    text-align: start;
    margin-top: 30px;
}
.insta-info div {
    font-weight: 700;
    font-size: 1.4em;
    letter-spacing: 1.2px;
}
.insta-info p{ 
    color : gray;
    font-size: 16px;
    margin-top: 5px;


}

@media (max-width: 760px) {
    .insta-profile {
        flex-direction: column;
        justify-content: start;
    }
    .insta-profile-image { 
        top : 15px;
     }
}