@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800&display=swap');

.il-section { 
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-wrap: wrap;
    background-color: "#F1F2F8";
    width: 90%;
    margin: 0 auto;
    padding-top: 2rem;
    justify-content: space-between;
    align-items: center;
}

.il-section .il-item {
   flex: 1;
   margin: 1rem;
    padding: 20px;
   border-radius: 5px;
   height: 450px;
   
   
}
.il-section .il-item h3 {
    font-family: "Poppins" , sans-serif;
    font-weight: 700;
    font-size: 1.1em;
    letter-spacing: 1.5px;
}
.il-section .il-item .il-description {
    font-weight: 400;
    font-size: 14px;
}


