.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .mymodal {
    position: absolute;
    
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 10px;
    outline: none;
    padding:4%

  }
  
  .myoverlay {
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex:1;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity .2s ease;


  }
  
  .ReactModal__Overlay {
    position: absolute;
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    position: absolute;

    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close {
    position: absolute;

    opacity: 0;
  }
  