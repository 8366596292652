.selector {
  width: 14vw;
  padding: 0px !important;
  border-radius: 15px !important;
  outline: 0px !important;
}
.react-google-flight-datepicker .date-picker-input {
  height: 2vw !important;
  border: 0px !important;
  margin-left: 0.5vw;
}
.react-google-flight-datepicker .dialog-header .date-picker-input {
  width: 30%;
}
.css-ahj2mt-MuiTypography-root {
  font-family: "Rubik" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 0;
}
.react-google-flight-datepicker .icon-calendar {
  width: 30px;
  height: 30px;
  fill: #5d5c66;
  position: relative;
  right: 0.5vw;
}
.react-google-flight-datepicker .icon-arrow {
  fill: white !important;
}
.react-google-flight-datepicker .dialog-date-picker.open {
  border-radius: 20px;
}
.react-google-flight-datepicker .day.selected::after {
  background-color: #5f41b3 !important;
}
.react-google-flight-datepicker .day.selected:hover {
  color: white !important;
  border: 2px solid #5f41b3;
}
.react-google-flight-datepicker .day:hover::after {
  border: 2px solid #5f41b3;
}
.css-1aquho2-MuiTabs-indicator {
  border: 2px solid #ffc725;
  width: 100px !important;
  margin-left: 2.2vw;
  display:none;
}
.react-google-flight-datepicker .date {
  width: 12vw !important;
}
.react-google-flight-datepicker .reset-button {
  visibility: hidden;
}
.react-google-flight-datepicker .dialog-footer .submit-button {
  visibility: hidden;
}
.react-google-flight-datepicker .dialog-footer {
  height: 0%;
  padding: 0%;
}
.react-google-flight-datepicker .day.selected.hovered {
  background: #e7e5f6;
}
.react-google-flight-datepicker .day.hovered {
  background-color: #e7e5f6 !important;
}
.react-google-flight-datepicker .day.selected.hovered.end {
  background: #e7e5f6;
}
.react-google-flight-datepicker .day.hovered.end {
  background: #e7e5f6;
}
.react-google-flight-datepicker .calendar-flippers {
  margin-bottom: 16vw;
  padding-right: 1vw;
}
.react-google-flight-datepicker .month-name {
  line-height: 20px;
  height: 2.5vw;
  align-items: center;
  justify-content: center;
  margin-bottom: 0%;
  padding-top: 15px;
  color: #362566;
}
.react-google-flight-datepicker .flipper-button {
  box-shadow: none;
  margin: 0%;
}
.react-google-flight-datepicker .flipper-button.disabled {
  visibility: hidden;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  border: none !important;
  font-size: 1.15vw !important;
  color: #5d5c66 !important;
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: 400 !important;
  margin-top: 0.1vw !important;
  padding: 0% !important;
  padding-left: 0px !important;
}
.react-google-flight-datepicker .week {
  color: #362566;
}
